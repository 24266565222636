import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/nestor.jpg" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/westpoint.jpg" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/atm.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Inseminação Artificial</span> <br></br>
              e Ciclo Reprodutivo<br></br> em Pequenos Ruminantes
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
              

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal12} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Nestor Chagas e Silva
                      </div>
                    
                    <div className="titulo inter-light">
                     Professor e Consultor de reprodução em ruminantes
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        5, 6, 19 e 20 de Março de 2022
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      500€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/brochura-PT-IA-PEQ-RUM-TURMA2.pdf" target="_blank" >
                <button className="btn-brochure inter-bold">Brochura</button> </a>
                </div> 
                 <div className="form">
                <a href="https://forms.gle/3yzWuHfXUWwrEmYj7" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Este curso prático tem por base a explicação avançada da fisiologia reprodutiva e ciclo éstrico dos ovinos e caprinos. Contempla todo o manuseamento e armazenagem do semén, bem como toda a sua preparação para inseminação. Inclui igualmente uma extensa parte prática dividida entre inseminação de animais e prática em peças recolhidas em matadouro. Este curso destina-se a técnicos e produtores que pretendam dar o próximo passo n reprodução de pequenos ruminantes.
                </div>
                <div className="logos">
                  
                

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          <br></br>Assim, a dinâmica do curso possibilita uma aprendizagem intensiva, organizada e coerente dos diferentes módulos abordados.
            </div>
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            +  Dia 1 - 05/03/2022
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Noções de anatomofisiologia genital
da ovelha e da cabra.</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:00 - 11:15</span> | <span className="mod">Módulo 2</span> - <span class="txt">Ciclo reprodutivo e ciclo éstrico da
ovelha e da cabra.</span></p>
                                </div>
                               
                                <div class="modulo">
                                  <p><span className="date">11:15 - 11:45</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:45 - 13:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Avaliação da função sexual no macho
e processamento do sémen.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Protocolos de sincronização de cios e
IA cervical em pequenos ruminantes.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 16:00</span> | <span className="mod">Módulo 5</span> - <span class="txt">Estudo e manipulação de aparelhos genitais recolhidos em matadouro (modelo de palpação) </span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:15</span> | <span className="mod">Coffee-Break</span> </p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:15 - 17:30</span> | <span className="mod">Módulo 6</span> - <span class="txt">Estudo e manipulação de aparelhos genitais recolhidos em matadouro (modelo de palpação) </span></p>
                                </div>
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +  Dia 2 - 06/03/2022
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">O bem-estar e o maneio reprodutivo
em pequenos ruminantes.</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:00 - 11:15</span> | <span className="mod">Módulo 2</span> - <span class="txt">Doenças do foro reprodutivo em pequenos ruminantes e protocolos vacinais.</span></p>
                                </div>
                               
                                <div class="modulo">
                                  <p><span className="date">11:15 - 11:45</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:45 - 13:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Azoto liquido e recipientes criobiologicos - preparação da dose seminal -  maneio e aplicação de semen congelado bovino.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
     
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                                <Accordion.Item eventKey="2">
                                <Accordion.Header>
                            
                            +  Dia 3 - 19/03/2022
                            </Accordion.Header>

                            <Accordion.Body>
                           
                            <div class="modulo">
                                <p><span className="date">09:00 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Pratica de inseminação artificial em exploração de caprinos</span></p>
                                </div> 
  
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 17:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Pratica de inseminação artificial em exploração de caprinos</span></p>
                                </div>
                          
          
                    

                                
                               </Accordion.Body>
                               </Accordion.Item>
                               <Accordion.Item eventKey="2">
                                <Accordion.Header>
                            
                            +  Dia 4 - 20/03/2022
                            </Accordion.Header>

                            <Accordion.Body>
                           
                            <div class="modulo">
                                <p><span className="date">09:00 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Pratica de inseminação artificial em exploração de ovinos</span></p>
                                </div> 
  
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 17:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Pratica de inseminação artificial em exploração de ovinos</span></p>
                                </div>
                          
          
                    

                                
                               </Accordion.Body>
                               </Accordion.Item>
                          
                             


                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/brochura-PT-IA-PEQ-RUM-TURMA2.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/3yzWuHfXUWwrEmYj7" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>

                
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
 

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal12} alt="" />

              </div>
              <div className="nome">
                Nestor Chagas e Silva
              </div>
              <div className="titulo">
                  Professor e Consultor de reprodução em ruminantes
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Professor – Universidade de Lisboa
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Departamento de Reprodução e Obstetrícia da Faculdade de Medicina Veterinária da Universidade de Lisboa</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Foi técnico especialista na Estação Nacional de Seleção e Reprodução Animal entre 1983 e 2007</p>
           
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
